import { useState, useEffect } from "react";
import logo from "../../assets/logo.svg";
import "./header.scss";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../helper/swithclang";
import { Link } from "react-router-dom";
import "../../helper/en.json";
import "../../helper/en.json";

const Header = () => {
  const [isMenuClicked, setIsmenuclicked] = useState(false);
  useEffect(() => {
    if (isMenuClicked) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMenuClicked]);
  const handleBurgerMenu = (e) => {
    setIsmenuclicked(!isMenuClicked);
    e.stopPropagation();
    console.log(e.target);
  };
  const containerStyles = {
    transition: "0.5s ease",
  };
  const { t } = useTranslation();
  return (
    <header className="header">
      <div className="company-hd-logo">
        <Link to="/">
          <img src={logo} alt="company-logo" />
        </Link>
      </div>
      <nav className="navigation">
        <ul className="ul-lists">
          <Link to="/">
            <li>{t("header1")}</li>
          </Link>
          <Link to="/">
            <li>{t("header2")}</li>
          </Link>

          <Link to="/contact">
            <li>{t("header3")}</li>
          </Link>
        </ul>
      </nav>
      <LanguageSwitcher />
      <div className="burger-menu" onClick={handleBurgerMenu}>
        <div className="lines-container">
          <div className="top-line line"></div>
          <div className="middle-line line"></div>
          <div className="bottom-line line"></div>
        </div>
      </div>
      {isMenuClicked && (
        <div className="burger-items-container" style={containerStyles}>
          <ul className="burger-items">
            <Link to="/">
              <li>{t("header1")}</li>
            </Link>
            <Link to="/">
              <li>{t("header2")}</li>
            </Link>
            <Link to="/contact">
              <li>{t("header3")}</li>
            </Link>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
