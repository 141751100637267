import basement from "./assets/services/basement.jpg";
import curtains from "./assets/services/curtains.jpg";
import events from "./assets/services/events.jpg";
import furniture from "./assets/services/furniture.jpg";
import hallway from "./assets/services/hallway.jpg";
import maintenance from "./assets/services/maintenance.jpg";
import railing from "./assets/services/railing.jpg";
import windows from "./assets/services/windows.jpg";
import yard from "./assets/services/yard.jpg";

export const services = [
  {
    name: "Maintenance cleaning",
    img: maintenance,
  },

  {
    name: "Cleaning after events",
    img: events,
  },
  {
    name: "Cleaning after tenants",
    img: railing,
  },
  {
    name: "Washing curtains",
    img: curtains,
  },
  {
    name: "Washing of furniture and carpets",
    img: furniture,
  },
  {
    name: "Tidying up the hallway",
    img: hallway,
  },
  {
    name: "Tidying up the basement",
    img: basement,
  },
  {
    name: "Cleaning the yard",
    img: yard,
  },
  {
    name: "Cleaning of glass showcases",
    img: windows,
  },
];
