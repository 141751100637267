import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Mobilecontact = () => {
  return (
    <>
      <a className="mobile-contact" href="tel:+995591950663">
        <FontAwesomeIcon icon={faPhone} />
        <button className="mobile">+995 591950663</button>
      </a>
    </>
  );
};

export default Mobilecontact;
