import Header from "../header/Header";
import { useTranslation } from "react-i18next";
import { services } from "../../data";
import "./inner.scss";
import Footer from "../footer/footer";
import Mobilecontact from "../../helper/mobilecontact";
import "../../helper/en.json";
import "../../helper/en.json";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const Inner = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="container">
        <div className="left-side"></div>
        <div className="middle-side">
          <div className="service">
            {services.map((service, index) => (
              <div key={index} className="services-container">
                <img
                  className="service-img"
                  src={service.img}
                  alt="alternativtext"
                />
                <div className="service-name-container">
                  <p className="service-name"> {t(service.name)}</p>{" "}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right-side"></div>
      </div>
      <Mobilecontact />
      <Footer />
    </>
  );
};

export default Inner;
