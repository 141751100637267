import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "../helper/en.json";
import geTranslations from "../helper/ge.json";
import ruTranslations from "../helper/ru.json";
import tuTranslations from "../helper/tu.json";

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: enTranslations,
    },
    Georgian: {
      translation: geTranslations,
    },
    Russian: {
      translation: ruTranslations,
    },
    Turkish: {
      translation: tuTranslations,
    },
  },
  lng: "Georgian",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
